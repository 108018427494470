import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom"
import { LastLocationProvider } from 'react-router-last-location';
import {Provider} from "mobx-react";
import mainStore from "./stores/mainStore";
import './normalize.css'


ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
        <LastLocationProvider>
          <Provider {...mainStore}>
            <App />
          </Provider>
        </LastLocationProvider>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
