import Calendar, {UsersList} from "../components/test/components/Calendar";
import Fetch from "../js/Fetch";
import React, {useState} from "react";
import Calendar2 from "../components/test/components/Calendar2";


function TestPageCalendar() {
  const [content, setContent] = useState({})
  // const [state, setState] = useState(null)
  // const [days, setDays] = useState([])
  const [project, setProject] = useState({})
  const [projects, setProjects] = useState([])

  const onChange = (state, dates) => {
    setProjects(prevState => prevState.map(i => ({...i, ...state.find(p => p.user.username === i.user.username)})))
    setProject(prevState => ({...prevState, dates: dates}))
  }
  // console.log('state', state)
  // console.log('days', days)

  return (<>
    <Calendar2
      usersContent={{}}
      content={content}
      setContent={setContent}
    />
    {/*  edit*/}
    {/*  get={Fetch.getCalendar}*/}
    {/*  usersContent={projects}*/}
    {/*  onChange={onChange}*/}
    {/*  username={localStorage.User}*/}
    {/*  noOffset={true}*/}
    {/*/>*/}
  </>)
}


export default TestPageCalendar
